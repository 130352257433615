<template>
  <div>
    <div class="home-banner">
      <img src="../../assets/logo.png"
           class="logo" />
    </div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <router-link :to="{path: '/companynotice', query: {code: code}}">
                <a>{{codeTitle}}</a>
              </router-link>
            </li>
            <li class="breadcrumb-item active"
                aria-current="page">详情</li>
          </ol>
        </nav>

        <div class="article-title">
          {{ info.title }}
        </div>

        <div class="article-info">
          <div class="article-info-left">
            <div class="article-time">{{ info.updated_at }}</div>
            <!-- <div class="article-time">来源：河北省应急管理宣传教育中心</div> -->
          </div>
          <div class="article-des">
            <span><i class="iconfont icon-flowrate"></i> {{ info.flowrate }}</span>
            <div @click="toFavourite">
              <span><i :class="[
                  'iconfont',
                  info.is_favourite == 1
                    ? 'icon-favourite_selected'
                    : 'icon-favourite_normal',
                ]"></i></span>
            </div>

            <!-- <span><i class="iconfont icon-favourite_selected"></i></span> -->
          </div>
        </div>
        <div class="rich-text"
             v-html="decodeContent(info.content)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paperDetail, toPaperFavourite } from "@/network/API.js";
export default {
  data() {
    return {
      info: {},
      code: "",
      codeTitle: "",
    };
  },
  created() {
    if (this.$route.params.id) {
      this.code = this.$route.params.code
      this.codeTitle = this.$route.params.code == 'notification' ? '通知公告' : '政策法规'
      this.getDetail(this.$route.params.id);
    }
  },
  methods: {
    async getDetail(id) {
      let data = await paperDetail(id);
      this.info = data;
    },
    async toFavourite() {
      let res = await toPaperFavourite({
        code: "notification",
        id: this.info.paper_id,
      });
      if (res.is_favourite == 1) {
        this.$toast("收藏成功");
      } else {
        this.$toast("取消收藏");
      }
      this.info.is_favourite = res.is_favourite;
    },
  },
};
</script>

<style>
.home-banner {
  margin-bottom: -30px;
}
</style>
