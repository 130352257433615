var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-wrap"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{path: '/companynotice', query: {code: _vm.code}}}},[_c('a',[_vm._v(_vm._s(_vm.codeTitle))])])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("详情")])])]),_c('div',{staticClass:"article-title"},[_vm._v(" "+_vm._s(_vm.info.title)+" ")]),_c('div',{staticClass:"article-info"},[_c('div',{staticClass:"article-info-left"},[_c('div',{staticClass:"article-time"},[_vm._v(_vm._s(_vm.info.updated_at))])]),_c('div',{staticClass:"article-des"},[_c('span',[_c('i',{staticClass:"iconfont icon-flowrate"}),_vm._v(" "+_vm._s(_vm.info.flowrate))]),_c('div',{on:{"click":_vm.toFavourite}},[_c('span',[_c('i',{class:[
                'iconfont',
                _vm.info.is_favourite == 1
                  ? 'icon-favourite_selected'
                  : 'icon-favourite_normal',
              ]})])])])]),_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(_vm.decodeContent(_vm.info.content))}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-banner"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/home"}},[_vm._v("首页")])])
}]

export { render, staticRenderFns }